/* ACTIVE SECTION */

.activeSection {
  background-color: #00bc9326;
  border-radius: 5px;
}

.EBookSection {
  background-color: rgba(29, 45, 57, 0.03);
  border-radius: 5px;
}

.activeSection img {
  filter: none !important;
}

.activeSection p {
  color: var(--green-aquinas) !important;
  font-weight: 600;
}

.newSection {
  text-align: end;
  width: 100%;
  color: var(--green-aquinas);
}
