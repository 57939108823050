.cardSection {
  margin: 0 auto;
  width: 300px;
  overflow: hidden;
  border-radius: 5px;
}

.cardSectionStyle {
  width: 300px;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s ease;
}

.cardSectionStyle:hover {
  transform: scale(1.1);
}

.CardSection__title {
  color: white;
  padding: 0 10px;
  font-size: 25px;
  font-weight: 700;
  width: 300px;
}
