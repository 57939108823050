.cardResource {
  background-color: white;
  max-height: 380px;
  width: 100%;
  border: 0.6px solid rgba(181, 187, 190, 0.53);
}

.cardResourceContainer {
  padding: 20px;
}

.cardResource__image {
  width: 100%;
  height: 150px;
  object-fit: contain;
  border-radius: 12px 12px 0 0;
}

.default__image {
  object-fit: cover;
}

.cardResource__title {
  color: #252525;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 15px;
  overflow: hidden;
  height: 43px;
}

.cardResource__text {
  font-size: 14px;
  color: #252525;
  overflow: hidden;
  height: 20px;
}

.resource {
  padding: 15px 15px 0px;
}
