.help {
  margin-top: 100px;
  min-height: calc(100vh - 100px);
  padding: 64px 0 100px;
  background-color: #f3f3f3;
}
@media (min-width: 1024px) {
  .help {
    margin-left: 300px;
  }
}
.helpContainer {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  width: 100%;
  margin: 0 auto;
}
@media (min-width: 760px) {
  .helpContainer {
    display: flex;
    gap: 50px;
  }
}
.helpContailer__FAQs {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.questionFAQs {
  padding: 12px;
  background-color: #eeeeee;
  font-size: 16px;
  font-weight: 700;
  border-radius: 5px 5px 0 0;
  transition: 1s;
}
.questionAnswer {
  padding: 12px;
  font-size: 16px;
  background-color: white;
  border-radius: 0 0 5px 5px;
}
.questionAnswer a {
  color: black;
  text-decoration: underline;
}
.helpContailer__getHelp {
  grid-column: 1 / 2;
  grid-row: 1 / 1;
  width: 100%;
  height: fit-content;
  background-color: #1b2831;
  color: white;
  font-size: 26px;
  font-weight: 300;
  padding: 24px;
  border-radius: 16px;
  margin-bottom: 40px;
}
@media (min-width: 760px) {
  .helpContailer__getHelp {
    width: 30%;
    max-width: 350px;
  }
}
.getHelpButton {
  padding: 13px;
  width: 100%;
  background-color: #00bc92;
  margin-top: 24px;
  border-radius: 12px;
  color: white;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.chatIconHelp {
  width: 30px;
}

.containerVideoHelp {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 30px;
}

@media (min-width: 870px) {
  .containerVideoHelp {
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
  }
}

/* CUSTOMER SERVICE */

.customServices {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  text-align: center;
}

.customServices p {
  font-size: 25px;
}

.customServices p:nth-child(2) {
  color: #00bc92;
  font-size: 20px;
  max-width: 250px;
}

@media (min-width: 550px) {
  .customServices p:nth-child(2) {
    color: #00bc92;
    font-size: 20px;
    max-width: 100%;
  }
}

.customServicesActions {
  display: flex;
  justify-content: center;
  gap: 30px;
  margin-top: 10px;
  color: white;
}

.customServicesbutton {
  width: 110px;
  height: 40px;
  border: none;
  border-radius: 6px;
  background-color: #00bc92;
  color: white;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  align-content: center;
}

.customServicesbutton.hcas{
  width: 180px;
}
