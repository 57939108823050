.container-component__olib {
  width: 100%;
  max-width: 1600px;
  margin: auto;
  padding: 0 1.5rem;
}

@media (min-width: 1700px) {
  .container-component__olib {
    /* padding: 0; */
  }
}
