.base-newsHeader {
  grid-template-columns: repeat(1, 1fr);
  gap: 20px;
  padding-top: 40px;
  min-height: 540px;
  display: none;
}

@media (min-width: 1650px) {
  .base-newsHeader {
    display: grid;
    padding-bottom: 40px;
    grid-template-columns: repeat(3, 1fr);
  }
}

.base-news {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 20px;
  padding-bottom: 40px;
  min-height: 560px;
  align-items: start;
}

@media (min-width: 1650px) {
  .base-news {
    grid-template-columns: repeat(2, 1fr);
  }
}

.base-newsHeaderSmall {
  padding-top: 40px;
  padding-bottom: 20px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 20px;
  align-items: start;
}

@media (min-width: 1650px) {
  .base-newsHeaderSmall {
    display: none;
  }
}

.newsFiltersContainer {
  display: flex;
  justify-content: end;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  padding-top: 20px;
}

.newsFiltersContainer
  .sectionFilterNews
  .filtersBarNews
  .filtersBarSectionNews
  .filtersBarSection__content.fixed {
  max-height: 500px;
}
