/* BAR */

.navigationBarResources {
  position: fixed;
  background-color: var(--white);
  height: 100%;
  width: 300px;
  display: flex;
  flex-direction: column;
  gap: 31px;
  top: 0;
  display: none;
  z-index: 52;
  overflow-y: auto;
}

@media (min-width: 1024px) {
  .navigationBarResources {
    display: block;
  }
}
.navigationBarResources.activeBar {
  display: block;
}

.navigationBarResourcesContainer {
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 31px;
}

.navigationBarResources .containerLogo {
  padding: 0 1rem;
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(27, 40, 49, 0.23);
}
@media (min-width: 1024px) {
  .navigationBarResources .containerLogo {
    justify-content: center;
  }
}

.navigationBarResources .containerLogo img {
  width: 135px;
}
.containerLogoClose {
  height: 100px;
  width: 30px;
}
@media (min-width: 1024px) {
  .navigationBarResources .containerLogo button {
    display: none;
  }
}

/* BACKGROUND */

.navigationBarBackground.activeBar {
  width: calc(100% - 300px);
  height: 100%;
  position: fixed;
  right: 0;
  bottom: 0;
  background-color: rgba(20, 30, 37, 0.34);
  z-index: 99999;
  cursor: pointer;
}

@media (min-width: 1024px) {
  .navigationBarBackground.activeBar {
    display: none;
  }
}

/* MAIN PANEL */

.navigationBarResources .panelSections {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0 1rem;
}

.navigationBarResources .panelSections a,
.navigationBarResources .panelSections div {
  display: flex;
  gap: 10px;
  padding: 10px;
}

.navigationBarResources .panelSections a p,
.navigationBarResources .panelSections div p {
  font-size: 15px;
}

.navigationBarResources .panelSections img {
  width: 17px;
  filter: brightness(0) saturate(100%) invert(0);
}

/* BUTTON */

.optionsNavigationBarContainer {
  height: 100px;
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  border-bottom: 1px solid rgba(27, 40, 49, 0.23);
  background-color: var(--white);
  z-index: 10;
}

.optionsNavigationBar {
  font-size: 20px;
}

@media (min-width: 1024px) {
  .optionsNavigationBarContainer {
    display: none;
  }
}
