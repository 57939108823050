.topicCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 20px 0;
  width: 100%;
  max-width: 150px;
  margin: 0 auto;
}
.topicCardImage {
  height: 60px;
  width: 55px;
  background-color: var(--green-aquinas);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}
.topicCardImage img {
  width: 22px;
  filter: brightness(0) invert(1);
}

.topicCardText {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.topicCardText h3 {
  font-size: 20px;
  font-weight: 700;
  color: var(--white);
  text-align: center;
  text-transform: capitalize;
}

.topicCardText p {
  font-size: 15px;
  font-weight: 400;
  color: var(--white);
  text-align: center;
}
