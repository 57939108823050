.EBooks {
  height: calc(100vh - 60px);
  width: 100%;
}

.EBooks h2 {
  font-size: 25px;
  font-weight: 400;
}

@media (min-width: 900px) {
  .EBooks h2 {
    font-size: 30px;
  }
}

.EBooksContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
}

.backButtonEBooks {
  color: rgba(27, 40, 49, 0.35);
  font-size: 15px;
  font-weight: 600;
}

.logoOnlineLibrary {
  margin: 0 auto;
  text-align: center;
}

.EBooksInitial {
  margin: 0 auto;
  height: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  max-width: 630px;
  text-align: center;
}

.EBooksInitial img {
  width: 70px;
}

@media (min-width: 900px) {
  .EBooksInitial img {
    width: 90px;
  }
}

.EBooksInfo {
  height: 80%;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 20px;
  align-items: center;
}

@media (min-width: 900px) {
  .EBooksInfo {
    grid-template-columns: repeat(2, 1fr);
  }
}

.EBooksInfoText {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

@media (min-width: 900px) {
  .EBooksInfoText {
    text-align: start;
    max-width: 600px;
  }
}

.EBooksInfoText .optionButtons {
  display: flex;
  gap: 10px;
  justify-content: center;
}

@media (min-width: 900px) {
  .EBooksInfoText .optionButtons {
    display: flex;
    gap: 10px;
    justify-content: start;
  }
}

.nextButtonEBooks {
  padding: 10px 23px;
  border-radius: 5px;
  background: var(--green-aquinas);
  color: white;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 15px;
}

.nextButtonEBooks:hover {
  transition: 0.3s;
  background: var(--green-aquinas-hover);
}

.imageEBooks {
  display: none;
}

@media (min-width: 900px) {
  .imageEBooks {
    display: block;
    width: 100%;
    border-radius: 5px;
    max-width: 950px;
  }
}

.aquinoImage {
  width: 160px;
  margin: 0 auto;
}

@media (min-width: 900px) {
  .aquinoImage {
    display: none;
  }
}
