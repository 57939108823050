.instructionsContainer {
  background-color: #f0f0f0;
  padding: 15px;
  color: black;
  /* margin-top: 24px; */
  border-radius: 12px;
  font-size: 14px;
  display: grid;
  grid-template-columns: 1fr;
  min-height: 200px;
  /* margin-top: 100px; */
  gap: 10px;
}
@media (min-width: 1200px) {
  .instructionsContainer {
    display: flex;
    padding: 20px;
    margin-top: 100px;
  }
}

@media (min-width: 1024px) {
  .instructionsContainer {
    margin-top: 100px;
    margin-left: 300px;
    margin-right: 370px;
  }
}

.instructionsContainer__video {
  width: 100%;
  padding: 20px 0 10px;
  grid-row: 3 / 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  background-color: var(--white);
  border-radius: 5px;
}

@media (min-width: 1200px) {
  .instructionsContainer__video {
    width: 60%;
    padding: 10px;
  }
}

.instructionsContainer__video p {
  color: #252525;
  text-align: center;
}

.instructionsContainer__instructions {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 3px;
  padding-bottom: 20px;
  background-color: var(--white);
  border-radius: 5px;
  padding: 10px;
}

@media (min-width: 1200px) {
  .instructionsContainer__instructions {
    width: 40%;
    padding-bottom: 0px;
    padding-left: 28px;
  }
}

.instructionsContainer__instructions h3 {
  font-size: 14px;
  margin-bottom: 5px;
}

.instructionsContainer__operator {
  width: 100%;
  max-width: 100px;
  background-color: #f0efef;
  color: #0a6d7b;
  text-align: center;
  border-radius: 5px;
  font-weight: 800;
}

.instructionsContainer__description {
  color: #252525;
}
.instructionsContainer__instructions div {
  display: flex;
  gap: 11px;
  align-items: center;
  justify-content: flex-start;
}

.instructionsContainer__instructions div img {
  width: 14.5px;
  height: 14.5px;
  margin-left: auto;
}

.instructionsContainer__close {
  grid-row: 1 / 1;
  display: flex;
  justify-content: end;
  align-items: flex-start;
  height: 20px;
}

.instructionsContainer__close button {
  margin-left: 10px;
}

/* Home section */

.instructionsContainer__homeFirst {
  width: 100%;
  padding: 20px 0 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-bottom: 1px solid black;
  padding-bottom: 20px;
}

@media (min-width: 1200px) {
  .instructionsContainer__homeFirst {
    width: 60%;
    gap: 20px;
    padding: 0 28px 0 0;
    border-right: 1px solid black;
    border-bottom: 0px solid black;
  }
}
.instructionsContainer__homeFirst p {
  text-align: center;
}

.instructionsContainer__homeSecond {
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding-top: 20px;
}
.instructionsContainer__homeSecond h3 {
  font-size: 16px;
}

@media (min-width: 1200px) {
  .instructionsContainer__homeSecond {
    padding-top: 0px;
    padding-left: 28px;
  }
}

/* IA section */

.instructionsContainer__instructionsIA {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 10px;
  background-color: var(--white);
  border-radius: 5px;
}

.instructionsContainer__instructionsIA h3 {
  font-size: 14px;
  margin-bottom: 5px;
}

.instructionsContainer__instructionsText {
  display: flex;
  flex-direction: column;
  gap: 10px;
  text-align: justify;
}

.instructionsContainer__instructionsText .instructionsContainer__operator {
  max-width: 100%;
  min-height: 45px;
  align-content: center;
}
